import React, { useState } from 'react';
import IMG2 from '../../assets/NuvebFinal.svg';
import { NavLink } from 'react-router-dom';

import IMG1 from '../../assets/discord.png';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className='fixed w-full flex justify-center lg:justify-between lg:px-[10vw]  bg-[#00040F] font-sans'>
      <a href='https://www.nuveb.com/'>
        {/* Change the website name here eventually */}
        {/* <div className='bg-gradient-to-r from-[#3F33CF] via-white to-[#33BBCF] inline-block text-transparent bg-clip-text text-4xl l-40 font-extrabold'>
          Nuveb
        </div> */}
        <img
          className='lg:h-[5vw] lg:w-[10vw] h-[10vh] w-[30vw] -top-[1vh] '
          // className='overflow-hidden h-16'
          src={IMG2}
          alt=''
        />
      </a>
      {isNavOpen ? (
        <div className='absolute h-[100vh] w-[100vw] flex justify-center text-center bg-inherit z-40'>
          <ul className='flex flex-col z-50 justify-center items-center w-full text-white'>
            <svg
              className='fill-white right-5 top-6 absolute transition ease-in-out delay-150'
              onClick={() => setIsNavOpen(false)}
              height='32px'
              id='Layer_1'
              version='1.1'
              viewBox='0 0 32 32'
              width='32px'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z' />
            </svg>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'Open Network for content | Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='/'
            >
              Home
            </NavLink>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'About | Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='/About'
            >
              About
            </NavLink>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'RSS | Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='https://getrss.app/'
              target='_blank'
            >
              GetRSS
            </NavLink>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'Blogs| Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='https://rawshots.substack.com/'
              target='_blank'
            >
              Blog
            </NavLink>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'Careers | Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='/Career'
            >
              Careers
            </NavLink>
            <NavLink
              onClick={() => setIsNavOpen(false)}
              className={(isActive) => {
                if (isActive.isActive) {
                  document.title = 'Contact | Nuveb';
                }
                return `text-xl font-bold p-4 hover:text-[#3F33CF] ${
                  isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
                }`;
              }}
              to='/Contact'
            >
              Contact
            </NavLink>
            <a href='https://discord.com/invite/RxUz8Czh9g'>
              <img className='m-4 h-7 w-7' src={IMG1} alt='discord icon' />
            </a>
          </ul>
        </div>
      ) : (
        <svg
          className='fill-white right-[3vw] top-[3vh] absolute transition-all  lg:hidden'
          onClick={() => setIsNavOpen(true)}
          height='32px'
          id='Layer_1'
          version='1.1'
          viewBox='0 0 32 32'
          width='32px'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z' />
        </svg>
      )}
      <ul className='max-lg:hidden flex justify-evenly -pr-[2vw] text-white'>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'Open Network for content | Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='/'
        >
          Home
        </NavLink>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'About | Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='/About'
        >
          About
        </NavLink>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'RSS | Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='https://getrss.app/'
          target='_blank'
        >
          GetRSS
        </NavLink>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'Blogs| Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='https://rawshots.substack.com/'
          target='_blank'
        >
          Blog
        </NavLink>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'Careers | Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='/Career'
        >
          Careers
        </NavLink>
        <NavLink
          className={(isActive) => {
            if (isActive.isActive) {
              document.title = 'Contact | Nuveb';
            }
            return `text-[2.4vh] font-bold p-[3vh] hover:text-[#3F33CF] ${
              isActive.isActive ? 'text-[#33BBCF]' : 'text-white'
            }`;
          }}
          to='/Contact'
        >
          Contact
        </NavLink>
        <a href='https://discord.com/invite/RxUz8Czh9g'>
          <img
            className='m-[3vh] h-[4vh] w=[4vh]'
            src={IMG1}
            alt='discord icon'
          />
        </a>
      </ul>
    </div>
  );
};

export default Header;
