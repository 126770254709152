import { Link, useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id='error-page'
      className='flex flex-col justify-center items-center lg:p-96 bg-[#00040F] h-[100vh] overflow-hidden'
    >
      <div className='font-extrabold text-6xl text-center text-white'>
        Oops!
      </div>
      <div className='font-extrabold text-5xl text-center text-white'>
        Sorry, an unexpected error has occurred.
      </div>
      <div className='font-extrabold text-4xl text-center text-white'>
        <i>{error.statusText || error.message}</i>
      </div>
      <Link
        className='text-xl font-bold p-4 text-[#33BBCF] hover:text-[#3F33CF]'
        to='/'
      >
        Back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
