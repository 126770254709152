import React from 'react';

const Signup = () => {
  return (
    <div className='flex flex-col justify-evenly align-middle lg:py-70 text-center lg:px-32 px-10'>
      <div className='text-[#33BBCF] font-extrabold lg:text-5xl text-3xl pt-32'>
        Sign up for early access
      </div>
      <div className='text-[#3F33CF]  lg:text-4xl text-2xl lg:pt-32 pt-16'>
        Join our mailing list to stay tuned on updates. We will not spam.
      </div>
      <div className='lg:py-32 pt-16 pb-32 relative'>
        <input
          className='rounded-full lg:w-3/12 h-10 text-xl pl-4'
          type='email'
          placeholder='Email Address'
        />
        <button className=' absolute rounded-full lg:right-[25vw] right-6 h-10 px-4 z-10 lg:w-44 bg-[#00040F] text-white text-xl font-bold hover:bg-[#3F33CF]'>
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default Signup;
