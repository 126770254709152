import React from 'react';
import IMG1 from '../../assets/instagram.png';
import IMG2 from '../../assets/twitter.png';
import IMG3 from '../../assets/linkedin.png';

const Footer = () => {
  return (
    <div className='flex text-center flex-col lg:flex-row justify-center relative h-28 border-t-2 border-t-[#31334A] bg-[#00040F]'>
      <div className='flex flex-row lg:justify-between justify-center lg:absolute lg:right-32 lg:bottom-3 py-4'>
        <div className='px-5 '>
          <a href='https://www.instagram.com/rawshotsapp/'>
            <img className='lg:h-14' src={IMG1} alt='Instagram' />
          </a>
        </div>

        <div className='px-5'>
          <a href='https://twitter.com/thenuveb'>
            <img className='lg:h-14' src={IMG2} alt='Twitter' />
          </a>
        </div>
        <div className='px-5'>
          <a href='https://www.linkedin.com/showcase/thenuveb/'>
            <img className='lg:h-14' src={IMG3} alt='LinkedIn' />
          </a>
        </div>
      </div>
      <div className='text-white lg:text-lg flex justify-center items-center '>
        ©2023 | All rights reserved by &nbsp;
        <span className='text-[#33BBCF]'> Nuveb Private Limited</span>
      </div>
    </div>
  );
};

export default Footer;
