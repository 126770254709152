import React from 'react';

const LabelCard = ({ LabelData }) => {
  return (
    <div className='border-2 border-white rounded-3xl flex flex-grow-0 flex-col justify-start px-1 py-6 m-[3vw] lg:w-1/5 shadow-white shadow-md'>
      <div className='flex justify-center pt-9'>
        <img
          className='h-28 w-28'
          src={LabelData.imgSrc}
          alt={LabelData.imgAlt}
        />
      </div>
      <div className='text-[#B7B6E9] text-center text-xl font-bold pt-9'>
        <span className='text-[#3F33CF]'>
          {LabelData.bigText1.split(' ')[0]}{' '}
        </span>
        {LabelData.bigText1.split(' ').slice(1).join(' ')}
      </div>
      <div className='text-center text-lg px-6 pt-9'>
        {LabelData.smallText1}
      </div>
    </div>
  );
};

export default LabelCard;
