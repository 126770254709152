import React, { useState } from 'react';

import AboutAccordion from './AboutAccordion';

import IMG1 from '../../../assets/krishna.png';
import IMG2 from '../../../assets/praveen.png';
import NuvebText from '../../Templates/NuvebText';

const About = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const QNA = [
    {
      id: 1,
      question: 'What is the Nuveb',
      answer: (
        <>
          <NuvebText /> as the name suggests is the new web being built to
          return your due sovereignty and democratize reach. <br />
          <br /> Beginning with professional media, we are building open network
          for content so that you can create, consume and monetize autonomously
          on the <NuvebText />. <br />
          <br />
          Get started now. Pour your raw creativity!
        </>
      ),
    },
    {
      id: 2,
      question: 'What makes Nuveb unique',
      answer: (
        <>
          Our first network, <NuvebText /> Screen is a peer-to-peer content
          sharing platform with tools for social and community commerce. <br />
          <br /> You can build and engage communities with exclusive content,
          merchandise and digital assets. <br />
          <br /> Leverage micropayments to autonomously choose how and how much
          you earn for your content.
        </>
      ),
    },
    {
      id: 3,
      question: 'What is an open network',
      answer: (
        <>
          An open network uses open standards and allows stakeholders to
          interact in an open loop.
          <br />
          <br />
          <NuvebText /> allows open interaction and mutual value exchange
          between creators, audience and brands without any platform
          intervention.
        </>
      ),
    },
    {
      id: 4,
      question: 'What kind of content is Nuveb meant for',
      answer: (
        <>
          <NuvebText />
          supports and encourages every form of user-generated (UGC) and
          Professionally-Generated Content (PGC). <br />
          <br /> However, in the earlier stages, the network might be most
          rewarding for User Generated Professional Content (UGPC) including
          music videos, podcasts, indie films, infotainment and streams.
        </>
      ),
    },
    {
      id: 5,
      question: 'Why should I join another social network',
      answer: (
        <>
          <NuvebText />
          is not another social network. It is the gateway to an open and more
          inclusive internet. <br />
          <br /> Our first network, Nuveb Screen is a content network with
          social interactions. <br />
          <br /> No more being held by gatekeepers. No more purchasing pricey
          subscriptions. No more intrusive advertisements. <br />
          <br /> You get a free preview for all paid content, and you pay for
          just whatever you watch.
        </>
      ),
    },
    {
      id: 6,
      question: 'Why create on Nuveb',
      answer: (
        <>
          <NuvebText />
          is being designed as an open protocol with distributed governance for
          you to pour your raw creativity without being suppressed by censorship
          risk and platform policies. <br />
          <br /> Further, we don't discriminate between content based on
          frequency of creation. <br />
          <br /> Create at your own pace and your content will reach your target
          audience.
        </>
      ),
    },
    {
      id: 7,
      question: 'What are dividend yielding campaigns',
      answer: (
        <>
          Why pitch endlessly to financiers who do not understand or appreciate
          your work? <br />
          <br /> Give a chance to the people who matter to you the most - your
          audience - to support you. <br />
          <br /> You can raise capital from your audience by selling ownership
          in your content. <br />
          <br /> Your earnings for the specific content will be shared as
          dividends to your supporters.
        </>
      ),
    },
    {
      id: 8,
      question: 'How to advertise on Nuveb',
      answer: (
        <>
          <a className='text-[#33BBCF]' href='https://ott.nuveb.com'>
            Nuveb Screen{' '}
          </a>
          is an open network for content. We value the time of creators and
          audiences. We also understand your requirements as a brand to reach
          more audience effectively. <br />
          <br /> You can collaborate with creators to integrate your brand and
          products with content through Nuveb Ads. <br />
          <br /> Contact our team to request access to the products.
        </>
      ),
    },
  ];

  return (
    <div className='bg-[#00040F] '>
      <div className=' flex flex-col text-white text-center lg:py-64 py-32 lg:px-96 px-10'>
        <div className='text-[#B7B6E9] text-6xl font-bold p-2'>
          Why&nbsp;
          <NuvebText />?
        </div>
        <div className='text-3xl text-[#B7B6E9] pt-20'>
          Everyone should be able to create digital content and businesses
          backed by communities, without entry barriers or capital crunch.
        </div>
        <div className='text-3xl text-white pt-20'>
          But platforms and centralized businesses today make it hard.
        </div>
        <div className='text-3xl text-[#B7B6E9] pt-20'>
          We're deeply bothered by the fact that creators and audience that make
          up platforms are left with no choice - of what to create, what to
          consume, what is the reach, how to monetize, what to share, what to
          keep private - nothing.
        </div>
        <div className='text-3xl text-white  pt-20'>
          We're building an{' '}
          <span className='text-[#33BBCF]'>&nbsp;open network&nbsp;</span> to
          return
          <span className='text-[#33BBCF]'>
            &nbsp;your due right back to you&nbsp;
          </span>
          .
        </div>
        <div className='text-3xl text-[#B7B6E9] pt-20'>
          Say no to gatekeepers and pricey subscriptions. ​
        </div>
        <div className='text-3xl text-white  pt-20'>
          On the &nbsp;
          <NuvebText />, spend your time, effort and money as you wish,
          beginning with professional media.
        </div>
      </div>
      <div>
        <div className='text-[#33BBCF] text-5xl font-extrabold text-center py-10'>
          Team
        </div>
        <div className='flex lg:flex-row flex-col justify-evenly items-center lg:p-36'>
          <div>
            <a href='https://www.linkedin.com/in/k-v-krishna'>
              <img className='bg-white rounded-full' src={IMG1} alt='Krishna' />
            </a>
            <div className='text-3xl text-[#B7B6E9] text-center p-4 '>
              Krishna
            </div>
          </div>
          <div>
            <a href='https://in.linkedin.com/in/h-l-praveen-raj'>
              <img className='bg-white rounded-full' src={IMG2} alt='Praveen' />
            </a>
            <div className='text-3xl text-[#B7B6E9] text-center p-4'>
              Praveen
            </div>
          </div>
        </div>
      </div>
      <div className='text-6xl font-bold p-2 pb-10 text-center text-[#33BBCF]'>
        Frequently asked questions
      </div>
      <div className='flex flex-col items-center pb-32'>
        {QNA.map((data) => (
          <AboutAccordion
            index={data.id}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            aboutData={data}
            key={data.id}
          />
        ))}
      </div>
    </div>
  );
};

export default About;
