import React from 'react';
import LabelCard from './LabelCard';
import NuvebText from '../../Templates/NuvebText';
import IMG1 from '../../../assets/broken-handcuffs.png';
import IMG2 from '../../../assets/audience.png';
import IMG3 from '../../../assets/grow.png';
import IMG4 from '../../../assets/bag-of-money.png';
import IMG5 from '../../../assets/money-network.png';

const Labels = () => {
  const labelArr = [
    {
      id: 1,
      imgSrc: IMG1,
      imgAlt: 'Broken handcuffs',
      bigText1: 'Create without constraints',
      smallText1:
        'Pour your raw creativity unsuppressed by everchanging platform policies',
    },
    {
      id: 2,
      imgSrc: IMG2,
      imgAlt: 'Audience',
      bigText1: 'Know Your Audience',
      smallText1: (
        <>
          Build and engage your communities with exclusive releases on{' '}
          <NuvebText />, merchandise and digital assets
        </>
      ),
    },
    {
      id: 3,
      imgSrc: IMG3,
      imgAlt: 'Grow your audience',
      bigText1: 'Grow your audience',
      smallText1: (
        <>
          Create at your own pace <br />
          Your releases will still reach your audience
        </>
      ),
    },
    {
      id: 4,
      imgSrc: IMG4,
      imgAlt: 'Bag of Money',
      bigText1: 'Monetize your audience',
      smallText1:
        'Leverage micro payments to autonomously choose how and how much you earn for your content ',
    },
    {
      id: 5,
      imgSrc: IMG5,
      imgAlt: 'Network of Money',
      bigText1: 'Reward your audience',
      smallText1:
        'Raise capital from your audience Share profits with them with dividend-yielding NFTs',
    },
  ];
  return (
    <div className='text-white flex flex-col lg:flex-row flex-wrap justify-center '>
      {labelArr.map((label) => (
        <LabelCard key={label.id} LabelData={label} />
      ))}
    </div>
  );
};

export default Labels;
