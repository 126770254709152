import React from 'react';

const LongCard = ({ cardData }) => {
  // const dir = !cardData.dir ? 'lg:text-left' : 'lg:text-right';
  const flexText = !cardData.dir ? 'lg:flex-row-reverse' : 'lg:flex-row';
  return (
    <div
      className={`flex flex-col ${flexText} text-left lg:pt-40 px-10 lg:px-32 justify-center lg:justify-evenly items-center `}
    >
      <div className='lg:w-2/5 flex flex-col justify-between py-20 '>
        <div>
          {cardData.bigText1}
          <div
            className={`text-[#3F33CF] text-4xl lg:text-5xl font-bold pt-2 pb-28 ${
              cardData.splText1 && 'pt-24 pb-44'
            } relative`}
          >
            {cardData.bigText2}
            {cardData.splText1 && (
              <>
                <div className='absolute lg:left-20 left-28 bottom-32 -rotate-[20deg]'>
                  {cardData.splText1}
                </div>
                <img
                  className='absolute lg:h-15 h-12 lg:left-20 left-28 bottom-16'
                  src={cardData.splImgSrc}
                  alt={cardData.imgAlt}
                />
                <img
                  src={cardData.splText2Src}
                  className='absolute left-28 lg:left-20 bottom-9 rotate-[30deg]'
                  alt='carat'
                />
              </>
            )}
          </div>
        </div>
        <div className='text-[#B7B6E9] lg:text-3xl text-2xl font-bold p-2'>
          {cardData.smallText1}
        </div>
        <div className='text-[#B7B6E9] lg:text-3xl text-2xl font-bold p-2'>
          {cardData.smallText2}
        </div>
      </div>
      <img
        className='pb-30 lg:pb-0'
        src={cardData.imgSrc}
        alt={cardData.imgAlt}
      />
    </div>
  );
};

export default LongCard;
