import React from 'react';
import IMG1 from '../../../assets/google-pay.png';
import IMG2 from '../../../assets/app-store.png';
import IMG3 from '../../../assets/connection.png';
import NuvebText from '../../Templates/NuvebText';

const Hero = () => {
  return (
    <div className='flex flex-col-reverse lg:flex-row pt-60 pb-30 lg:px-32 px-10 justify-evenly'>
      <div className='lg:w-2/5 text-center lg:text-left flex flex-col justify-between xl:text-left pb-32 '>
        <div className='text-white text-5xl lg:text-5xl font-extrabold lg:py-2 py-10'>
          An open OTT network
        </div>
        <div className='text-[#B7B6E9] text-3xl lg:text-3xl font-semibold lg:pt-[8vh] py-[4vh]'>
          Pour your
          <span
            className='
         text-[#3F33CF] '
          >
            {' '}
            raw
          </span>
          <span
            className='
         text-[#33BBCF] '
          >
            {' '}
            creativity
          </span>
          !
        </div>
        <div className='text-[#B7B6E9] text-3xl lg:text-3xl font-medium lg:pb-[8vh] py-[4vh]'>
          Create, consume and monetize autonomously on the <NuvebText />
        </div>
        <div className='flex py-6 flex-row  max-lg:justify-center max-lg:flex-wrap'>
          {/* <a href='#'> */}
          {/* Make these buttons work eventually */}
          <img className='max-lg:h-32 h-16' src={IMG1} alt='Google Play' />
          {/* </a> */}
          {/* <a href='#'> */}
          <img className='max-lg:h-32 h-16' src={IMG2} alt='App-Store' />
          {/* </a> */}
        </div>
      </div>
      <img
        className='max-lg:h-[80vw] h-96 max-lg:w-[80vw] max-lg:m-auto'
        src={IMG3}
        alt='Connections'
      />
    </div>
  );
};

export default Hero;
