import React from 'react';
import Hero from './Hero';
import Long from './Long';
import Signup from './Signup';
import Labels from './Labels';

const Home = () => {
  return (
    <div className='bg-[#00040F]'>
      <Hero />
      <Labels />
      <Long />
      <Signup />
    </div>
  );
};

export default Home;
