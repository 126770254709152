import React from 'react';

const AboutAccordion = ({ aboutData, index, openIndex, setOpenIndex }) => {
  return (
    <div className=' border-b-4 border-[#33BBCF] lg:w-7/12 w-11/12 '>
      <div
        className={`text-white text-left flex w-full flex-col items-start pb-11 pt-5`}
        onClick={() => {
          if (openIndex === index) {
            setOpenIndex(null);
          } else {
            setOpenIndex(index);
          }
        }}
      >
        <div className='text-2xl pt-3 font-bold text-white w-full flex flex-row justify-between'>
          <div>{aboutData.question}?</div>
          {openIndex === index ? '‸' : '⌄'}
        </div>
        {openIndex === index && (
          <div className='text-xl pt-7 font-thin w-full  text-white  '>
            {aboutData.answer}
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutAccordion;
