import React from 'react';

const Career = () => {
  return (
    <div className='bg-[#00040F] flex flex-col text-white text-center py-44 max-lg:h-[90vh] lg:py-56 lg:px-96 overflow-hidden'>
      <div className='text-[#33BBCF] text-5xl font-bold p-2'>Join Us</div>
      <div className='lg:text-3xl text-xl px-5 pt-14'>
        We envision an equitable, fun, and secure digital world, by harnessing
        the power of autonomous communities - beginning with an open network for
        content.
      </div>
      <div className='lg:text-3xl text-xl px-5 text-[#B7B6E9] pt-14'>
        If you like your shots raw, find yourself pouring irrational conviction,
        and are an eccentric entrepreneur at heart, we're excited to hear from
        you!
      </div>
      <div className='lg:text-3xl text-xl px-5 pt-14'>
        If you think you'd be a good fit, drop an
        <a href='mailto:teamrawshots@gmail.com' className='text-[#33BBCF]'>
          &nbsp;email&nbsp;
        </a>
        to get a conversation started.
      </div>
    </div>
  );
};

export default Career;
