import React from 'react';
import LongCard from './LongCard';
import IMG1 from '../../../assets/hourglass.png';
import IMG2 from '../../../assets/entre.png';
import SPLIMG1 from '../../../assets/carat.png';
import SPLIMG2 from '../../../assets/squiggly.png';
import IMG3 from '../../../assets/put-coin.png';
import IMG4 from '../../../assets/no-label.png';

const Long = () => {
  const dataArr = [
    {
      id: 1,
      dir: false,
      imgSrc: IMG1,
      imgAlt: 'hourglass',
      bigText1: 'Content determines reach',
      bigText2: 'Not frequency...',
      smallText1:
        "Don't let algorithms dictate what, when and how often you post",
      smallText2:
        "Create at your pace and we'll help your audience find your content",
    },
    {
      id: 2,
      dir: true,
      imgSrc: IMG2,
      imgAlt: 'Labels Dont make you',
      bigText1: "Labels don't make you",
      bigText2: 'You make the label',
      smallText1: 'You are an entrepreneur \n Your content is your startup',
      smallText2: 'Own your content and its success!',
      splText1: 'are',
      splText2Src: SPLIMG1,
      splImgSrc: SPLIMG2,
    },
    {
      id: 3,
      dir: false,
      imgSrc: IMG3,
      imgAlt: 'Labels Dont make you',
      bigText1: 'Grow your content venture',
      bigText2: '',
      smallText1:
        'Raise capital from your Nuveb by selling ownership and fan experiences through NFTs',
      smallText2: 'Supporters earn dividends as your content performs',
    },
    {
      id: 4,
      dir: true,
      imgSrc: IMG4,
      imgAlt: 'Labels Dont make you',
      bigText1: 'Be your own Producer',
      bigText2: '',
      smallText1:
        'Integrate with brands, place products in video, \n manage promotions and finances',
      smallText2: 'All at one place!',
    },
  ];

  return (
    <>
      {dataArr.map((data) => (
        <LongCard key={data.id} cardData={data} />
      ))}
    </>
  );
};

export default Long;
