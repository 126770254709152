import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';

import App from './App';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About/About';
import Career from './Components/Pages/Career/Career';
import Contact from './Components/Pages/Contact/Contact';
import ErrorPage from './Components/Pages/Error/ErrorPage';

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/Contact',
        element: <Contact />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/Career',
        element: <Career />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/About',
        element: <About />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter} />
  </React.StrictMode>
);
