import React from 'react';

const NuvebText = () => {
  return (
    <a href='https://nuveb.com'>
      <span className='text-[#3F33CF] font-semibold'>Nu</span>
      <span className='text-[#33BBCF] font-semibold'>veb</span>&nbsp;
    </a>
  );
};

export default NuvebText;
