import React from 'react';

const Contact = () => {
  return (
    <div>
      <div className='bg-[#00040F] flex flex-col text-white text-center py-80 lg:px-96'>
        <div className='text-[#33BBCF] text-6xl font-bold p-2'>
          Get in touch
        </div>
        <div className='lg:text-3xl text-xl px-5 text-[#B7B6E9] pt-28'>
          For any queries or support, drop us an
          <span className='text-[#33BBCF]'>&nbsp;email</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
